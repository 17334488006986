<template>
  <div class="footer__inner">
    <div class="footer__container">
      <div class="footer__container--wrap">
        <router-link to="/" class="footer__logo">
          <img
            src="@/assets/images/logo.svg"
            width="71"
            height="71"
            alt="logo"
          />
        </router-link>
        <p class="footer__info--name">Компанія «iIT Trading»</p>
        <div class="footer__container--inner">
          <lang-switcher></lang-switcher>
          <div class="footer__search">
            <button
              class="footer__search--btn"
              @click="$emit('showFormSearch')"
            >
              search
            </button>
          </div>
        </div>
      </div>
      <div class="footer__content">
        <div class="footer__menu">
          <router-link to="/" class="footer__menu--home">Головна</router-link>
          <ul class="footer__menu--list menu--list" v-if="menu.length > 0">
            <li class="menu--list__item" v-for="item in menu" :key="item.id">
              <router-link class="menu--list__link" :to="item.url">{{
                getTranslatedTitle(item.translations, $i18n.locale)
              }}</router-link>
            </li>
          </ul>
        </div>
        <address class="footer__info--address">
          м. Київ, Україна, 03118, вул. Професора Буйко, 24
        </address>
        <a href="#" class="footer__info--map">мапа проїзду</a>
      </div>
    </div>

    <div class="footer__mobile">
      <div class="footer__call">
        <div class="footer__call--number">
          <a href="tel:+380443399977">
            <span>+38 (044) 33-999-77</span>
            <span>Зателефонувати</span>
          </a>
          <span>Київ</span>
        </div>
      </div>
      <div class="footer__info">
        <div class="footer__social">
          <a
            class="footer__social--link fb"
            :href="footer.facebook"
            target="_blank"
            >facebook</a
          >
          <a
            class="footer__social--link in"
            :href="footer.linkedin"
            target="_blank"
            >linkedin</a
          >
        </div>
      </div>
    </div>

    <div class="footer__copy">
      <div class="footer__copy--left" v-if="translations[$i18n.locale]">
        {{ getField("rights_reserved", $i18n.locale) }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const LangSwitcher = defineAsyncComponent(() =>
  import("@/components/UI/LangSwitcher")
);
// import VLazyImage from "v-lazy-image";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  components: {
    LangSwitcher,
    // VLazyImage,
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
    footer: {
      type: Array,
      required: true,
    },
    translations: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getTranslations(locale) {
      return this.translations[locale];
    },
    getField(fieldName, locale) {
      return this.getTranslations(locale)[fieldName];
    },
    getTranslatedTitle(transArray, locale) {
      let title = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          title = element.title;
        }
      });
      return title;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.footer {
  &__container {
    max-width: 1396px;
    margin: 0 auto;
    padding: 0 15px;
    display: grid;

    &--wrap {
      display: grid;
      grid-template-columns: 60px 1fr max-content;
      align-items: center;
      grid-gap: 15px;
      margin-bottom: 25px;
    }

    &--inner {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__logo {
    display: block;
    align-self: flex-start;

    img {
      display: block;
    }
  }

  &__menu {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    justify-content: space-between;

    &--home {
      flex: 0 0 auto;
      width: 17px;
      height: 16px;
      font-size: 0;
      background: #f9b80e;
      mask-image: url("~@/assets/images/home.svg");
      mask-size: 100% 100%;
      mask-repeat: no-repeat;
      margin-top: 6px;

      @media (hover: hover) {
        &:hover {
          background: #fff;
        }
      }
    }
  }

  &__search {
    display: flex;
    padding: 8px 0;
    margin-top: 5px;

    &--btn {
      position: relative;
      font-size: 0;
      width: 16px;
      height: 16px;
      background: url("~@/assets/images/search.svg") no-repeat;

      &::before {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 0;
        width: 0;
        height: 3px;
        background-color: #f9b80e;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;
      }
    }
  }

  &__call {
    min-width: max-content;
    margin-right: 15px;
    margin-bottom: 15px;

    &--number {
      display: flex;
      align-items: center;

      a {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 20px;
        margin-right: 10px;
        line-height: calc(26 / 20);
        text-decoration: none !important;

        &::before {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          flex: 0 0 auto;
          margin-right: 15px;
          background: url("~@/assets/images/phone-icon.svg") no-repeat;
          background-size: 100% 100%;
        }

        span {
          transition: all 0.3s linear;
          &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 31px;
            transform: translateY(-50%);
            opacity: 0;
            visibility: hidden;
            color: #f9b80e;
          }
        }
      }

      > span {
        font-size: 20px;
        line-height: calc(26 / 20);
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    margin: 0;

    &--link {
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-color: #f9b80e;
      mask-repeat: no-repeat;
      mask-size: 100% 100%;

      &:not(:last-child) {
        margin-right: 18px;
      }

      &.fb {
        mask-image: url("~@/assets/images/fb.svg");
      }

      &.in {
        mask-image: url("~@/assets/images/in.svg");
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    > * {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    &--name {
      margin: 0;
      font-size: 1rem;
      line-height: calc(25 / 16);
      letter-spacing: 0.8px;
      color: #e1e1e1;
      text-transform: uppercase;
      margin-right: auto !important;
    }

    &--address {
      position: relative;
      display: flex;
      font-size: 1rem;
      line-height: calc(30 / 16);
      color: #e1e1e1;
      font-style: normal;

      &::before {
        content: "";
        display: block;
        width: 12px;
        height: 16px;
        flex: 0 0 auto;
        background: url("~@/assets/images/address.svg") no-repeat;
        background-size: 100% 100%;
        margin-top: 7px;
        margin-right: 8px;
      }
    }

    &--map {
      text-decoration: underline;
      font-size: 1rem;
      line-height: calc(25 / 16);
      letter-spacing: 0.8px;
      color: #f9b80e;
      margin-left: 20px;
    }
  }

  &__copy {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 5px 20px;
    max-width: 1396px;
    padding: 0 15px;
    margin: 0 auto;
    font-size: 14px;
    line-height: calc(30 / 14);
    color: #afafaf;
    margin-top: 5px;

    &--logo {
      position: relative;
      top: 4px;
      display: inline-flex;
    }
  }

  &__ipad {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 0 20px;
    padding: 0 15px;
    margin-top: 20px;
  }

  &__mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px;
    margin-top: 20px;
  }
}

.menu--list {
  display: grid;
  grid-template-columns: 1.12fr 1fr;
  grid-gap: 5px;
  margin-left: 15px;
  margin-right: 0;
  width: 100%;
  margin: 0 20px;

  &__item {
    &:not(:last-child) {
      margin-right: 15px;
    }
    padding: 5px 0;
  }

  &__link {
    font-size: 1rem;
    line-height: calc(20 / 16);
    color: #f9b80e;
    text-decoration: none !important;
  }
}

.search--form {
  display: flex;
  align-items: center;
  justify-content: center;

  &__label {
    position: relative;
    max-width: 812px;
    width: 100%;

    input {
      font-size: 18px;
      line-height: calc(23 / 18);
      font-style: italic;
      letter-spacing: 0.9px;
      color: #e1e1e1;
      padding: 7px 0;
      width: 100%;
      border-bottom: 0.5px solid #e1e1e1;

      &:focus,
      &:valid {
        ~ label {
          top: -5px;
        }
      }
    }

    label {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      margin: 0;
      font-size: 18px;
      line-height: calc(23 / 18);
      font-style: italic;
      letter-spacing: 0.9px;
      color: #e1e1e1;
      transition: all 0.3s linear;
      cursor: text;
    }
  }

  button {
    padding: 4px 17px 5px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin-left: 20px;
    transition: all 0.3s linear;
  }
}
</style>
